import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles.css";
import "./header.css";
import logoCLTO from "../img/Badminton_CLTOBadminton.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faBars,
	faCalendarDays,
	faNewspaper,
} from "@fortawesome/free-solid-svg-icons";

const Header: React.FC = () => {
	const location = useLocation();
	const isHome = location.pathname !== "/";
	const isConnected = true;
	return (
		<div className='header '>
			{isHome && (
				<Link to='/' className='buttonmenu-header'>
					<span role='img' aria-label='Return'>
						<FontAwesomeIcon icon={faArrowLeft} />
					</span>
				</Link>
			)}
			{!isHome && (
				<a
					href='http://www.cltobadminton.fr/news/news_controller'
					target='_blank'
					rel='noopener noreferrer'
				>
					<div className='buttonmenu-header'>
						<span role='img' aria-label='News'>
							<FontAwesomeIcon icon={faNewspaper} />
						</span>
					</div>
				</a>
			)}
			<span className='header-title'>Pointage</span>
			<span className='header-logo'>
				<img src={logoCLTO} alt='CLTO Badminton' className='logo-image' />
			</span>
		</div>
	);
};
/* autre boutons
<Link to="/creneau" className="buttonmenu-header">
      <span role="img" aria-label="Créneaux">
      <FontAwesomeIcon icon={faCalendarDays} />
      </span>
    </Link>
<Link to="/menu" className="buttonmenu-header menu">
      <span role="img" aria-label="Menu">
      <FontAwesomeIcon icon={faBars} />
      </span>
    </Link>
    */
export default Header;
