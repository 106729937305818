// Creneau.ts
export class Creneau {
    constructor(
        public id: number, 
        public nom: string, 
        public jour_semaine: number, 
        public gymnase_id: number,
        public debut_creneau: string, 
        public fin_creneau: string,
        public saison_id : number) { }
}