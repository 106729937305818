import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles.css";
import "./container.css";
import "./historiquesaisie.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import Layout from "../layout/layout";
import { loadSaisiesDetail } from "../api/LocalData";
import { Saisie } from "../class/saisie";
import { ISaisieDetail } from "../interface/ISaisieDetail";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Importer FontAwesomeIcon
import {
	faPeopleRoof,
	faStopwatch,
	faCalendarDays,
	faUserFriends,
	faUserAlt,
	faUserEdit,
} from "@fortawesome/free-solid-svg-icons"; // Importer les icônes
const Container: React.FC = () => {
	const navigate = useNavigate();
	const isConnected = false;
	const [selectedGymnasium, setSelectedGymnasium] = useState<number>(0);
	const [selectedDate, setSelectedDate] = useState<string>("");
	const [selectedCreneau, setselectedCreneau] = useState<number>(0);
	const [selectedSaisie, setselectedSaisie] = useState<number>(0);
	const simulatedHistoriqueSaisie: Promise<ISaisieDetail[]> =
		loadSaisiesDetail(); // Données simulées
	const [saisiesDetails, setSaisieDetail] = useState<ISaisieDetail[]>([]);
	// Utilisation des données simulées pour l'historique

	const handleModifySaisie = (saisieDetail: ISaisieDetail) => {
		let saisie: Saisie = saisieDetail as unknown as Saisie;
		saisie.date_saisie = new Date(
			saisie.date_saisie.toString().replace(" ", "T")
		);
		navigate("/saisie", { state: { saisieAModifier: saisie } });
	};
	useEffect(() => {
		// Chargement initial des données
		Promise.all([simulatedHistoriqueSaisie])
			.then(([saisieDetailData]) => {
				setSaisieDetail(saisieDetailData);

				// ... Autres logiques d'initialisation ...
			})
			.catch((error) => {
				// Gérer les erreurs de chargement des données
				console.error("Error loading data:", error);
			});
	}, []);

	return (
		<Layout isConnected={isConnected}>
			<div className='content'>
				<h1 className='title'>Historique des Saisies</h1>
				<div className='historique-list'>
					{saisiesDetails.map((saisieDetail) => (
						<div className='historique-item' key={saisieDetail.id}>
							<div className='historique-info'>
								<div className='historique-date-gymnasium'>
									<div className='historique-date'>
										<FontAwesomeIcon
											icon={faCalendarDays}
											className='historique-icon'
										/>
										{format(new Date(saisieDetail.date_saisie), "dd/MM/yyyy", {
											locale: fr,
										})}
									</div>
									<div className='historique-gymnasium'>
										{saisieDetail.nom_court_gymnase}
									</div>
								</div>
								<div className='historique-periode-creneau'>
									<div className='historique-creneau'>
										<FontAwesomeIcon
											icon={faPeopleRoof}
											className='historique-icon'
										/>
										{saisieDetail.nom_creneau}
									</div>
									<div className='historique-periode'>
										<FontAwesomeIcon
											icon={faStopwatch}
											className='historique-icon'
										/>
										{saisieDetail.debut_creneau.substring(0, 5)} -{" "}
										{saisieDetail.fin_creneau.substring(0, 5)}
									</div>
								</div>
								<div className='historique-nb-personnes'>
									<FontAwesomeIcon
										icon={faUserAlt}
										className='historique-icon'
									/>
									{saisieDetail.nb_pointages}
								</div>
								<button
									className='primary-button check historique-button'
									onClick={() => handleModifySaisie(saisieDetail)}
								>
									<FontAwesomeIcon icon={faUserEdit} />
								</button>
							</div>
						</div>
					))}
				</div>
			</div>
		</Layout>
	);
};

const historiquesaisie = () => {
	return (
		<div className='rootpage container'>
			<Header />
			<Container />
			<Footer />
		</div>
	);
};

export default historiquesaisie;
