import React from 'react';
import "../styles.css";
import "./container.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import Layout from "../layout/layout";

const Container: React.FC = () => {
  
  const isConnected = false;
  const contacts = [
    {
      name: 'John Doe',
      email: 'john.doe@example.com',
      phone: '+1234567890',
    },
    {
      name: 'Jane Smith',
      email: 'jane.smith@example.com',
      phone: '+0987654321',
    },
    // Ajoutez d'autres contacts si nécessaire
  ];

  const handleSupport = () => {
    // Ajouter ici la logique d'envoi du message à contact@cltobadminton.fr
    // Peut-être en utilisant une librairie pour les formulaires de contact
  };

  return (
    <Layout isConnected={isConnected}>
      <div className="content">
        <h1 className="title">Contact</h1>
        <div className="contact-container">
        
        <ul className="contact-list">
          {contacts.map((contact, index) => (
            <li key={index}>
              <h3>{contact.name}</h3>
              <p>Email: {contact.email}</p>
              <p>Téléphone: {contact.phone}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="support-container">
      <h1 className="title">Support</h1>
      <p>Pour toute question ou demande d'assistance, veuillez nous contacter via le formulaire ci-dessous :</p>
      <form className="contact-form" onSubmit={handleSupport}>
        <div className="form-group">
          <label htmlFor="name">Nom : </label>
          <input type="text" id="name" name="name" />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email : </label>
          <input type="email" id="email" name="email" />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message : </label>
          <textarea id="message" name="message" rows={5}></textarea>
        </div>
        <button className="btn btn-primary button" type="submit">
          Envoyer
        </button>
      </form>
      </div>
    </div>
    </Layout>
  );
};

const Contact = () => {
    return (
      <div className="rootpage">
       <Header />
       <Container />
       <Footer />
      </div>
    
    );
  };

export default Contact;
