export class Saisie {
  map(arg0: (sai: any) => any) {
    throw new Error("Method not implemented.");
  }
  public id!: number;
  constructor(
    public gymnase_id:number,
    public creneau_id: number,
    public date_saisie: Date,
    public saison_id: number
  ){ }
}
export class SaisieInsert  {
  constructor(
    public gymnase_id:number,
    public creneau_id: number,
    public date_saisie: Date,
    public saison_id: number
  ) { }
}

export class SaisieDetail extends Saisie {
  constructor(
    public id: number,
    public gymnase_id: number,
    public creneau_id: number,
    public date_saisie: Date,
    public saison_id: number,
    public nom_court_gymnase: string,
    public nom_creneau: string,
    public jour_semaine: string,
    public debut_creneau: string,
    public fin_creneau: string,
    public nb_pointages: number
  ) {
    super(gymnase_id,
      creneau_id,
      date_saisie,
      saison_id);
   }
  
}


