import React, { ReactNode } from "react";
import FloatingButton from "./floatingButton"; 

interface LayoutProps {
  isConnected: boolean;
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ isConnected, children }) => {
  return (
    <div className="layout">
      {children}
      <FloatingButton isConnected={isConnected} />
    </div>
  );
};

export default Layout;
