import React from "react";
import "./toggleElement.css";


interface ToggleElementProps {
  isChecked: boolean;
  onChange: (newValue: boolean) => void;
}

const ToggleElement: React.FC<ToggleElementProps> = ({ isChecked, onChange }) => {
  const handleToggleChange = () => {
    onChange(!isChecked);
  };

  return (
    <div className="toggle-element">
      <label className="toggle-label">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleToggleChange}
          className="toggle-input"
        />
        <div className={`toggle-switch ${isChecked ? "checked" : ""}`} />
      </label>
    </div>
  );
};

export default ToggleElement;
