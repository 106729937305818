import React from "react";
import { Link } from "react-router-dom";
import "../styles.css";
import "./footer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faCalendarDays, faRectangleList, faContactBook, faMailForward, faContactCard, faHeadset, faKey, faSignIn, faSignOut, faSignHanging, } from '@fortawesome/free-solid-svg-icons'

const Footer: React.FC = () => {

  const isConnected = false;


  return (
    <div className="footer">
      <Link to="/" className="buttonmenu-footer">
      <span role="img" aria-label="Acceuil">
      <FontAwesomeIcon icon={faHome} />
      </span>
      </Link>
      <Link to="/historique" className="buttonmenu-footer">
      <span role="img" aria-label="Historique">
      <FontAwesomeIcon icon={faRectangleList} />
      </span>
    </Link>
  </div>
    )
};

/* autres boutons
    <Link to="/contact" className="buttonmenu-footer">
      <span role="img" aria-label="Contact">
      <FontAwesomeIcon icon={faHeadset} />
      </span>
    </Link>
    {!isConnected && (<Link to="/connexion" className="buttonmenu-footer">
      <span role="img" aria-label="Connexion">
      <FontAwesomeIcon icon={faSignIn} />
      </span>
    </Link>
    )}
    {isConnected && (<Link to="/connexion" className="buttonmenu-footer">
      <span role="img" aria-label="Connecté">
      <FontAwesomeIcon icon={faContactCard} />
      </span>
      </Link>
    )}*/
export default Footer;
