import React from "react";
import { Link } from "react-router-dom";
import "./layout.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus} from "@fortawesome/free-solid-svg-icons";


interface FloatingButtonProps {
  isConnected: boolean;
}

const FloatingButton: React.FC<FloatingButtonProps> = ({ isConnected }) => {
  return (
    <div className="floating-button-container">
      {isConnected && (
        <Link to="/saisie" className="floating-button">
          <span role="img" aria-label="Pointage">
            <FontAwesomeIcon icon=  {faPlus} /> Nouvelle saisie
          </span>
        </Link>
      )}
    </div>
  );
};

export default FloatingButton;
