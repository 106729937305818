import React, { FormEvent } from 'react';
import "../styles.css";
import "../layout/layout.css";
import "./container.css";
import Header from '../header/header';
import Footer from '../footer/footer';
import Layout from '../layout/layout';

const Container: React.FC = () => {
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    // Ajoutez ici la logique de gestion de la soumission du formulaire
  };

  return (
    <Layout isConnected={false}>
      <div className="content">
      <h1 className="title">Se connecter à mon compte</h1>
     <div className="login-container">
      <div className="form-header">
      </div>
      <div className="form-body">
        <form className="login-form" onSubmit={handleSubmit}>
          <fieldset>
            <div className="form-group">
              <label htmlFor="numLicence">Numéro de licence</label>
              <input type="text" id="numLicence" name="numLicence" />
            </div>
            <div className="form-group">
              <label htmlFor="password">Mot de passe</label>
              <input type="password" id="password" name="password" />
            </div>
            <div className="form-group">
              <label>
                <input type="checkbox" name="remember" defaultChecked /> Rester connecté
              </label>
            </div>
            <button className="btn btn-primary button" type="submit">
              Se connecter
            </button>
          </fieldset>
        </form>
      </div>
      <div className="form-footer">
        <p>
          Mot de passe perdu ? <a href="mailto:contact@cltobadminton.fr">Cliquez-ici pour réinitialiser.</a>
        </p>
      </div>
    </div>
    </div>
    </Layout>
  );
};
const Connexion = () => {
    return (
      <div className="rootpage">
       <Header />
       <Container />
       <Footer />
      </div>
    
    );
  };

export default Connexion;
