import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import "../styles.css";
import "./container.css";
import "./saisie.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Importer FontAwesomeIcon
import {
	faUserCheck,
	faCheckCircle,
	faUserNinja,
	faPlusCircle,
	faTimesCircle,
	faMinusSquare,
	faPlusSquare,
	faUserAltSlash,
	faUserEdit,
} from "@fortawesome/free-solid-svg-icons"; // Importer les icônes
import Header from "../header/header";
import Footer from "../footer/footer";
import Layout from "../layout/layout";
import ToggleElement from "../function/toggleElement";
import AddPlayerPopup from "./addPlayerModal"; // Importez le composant de la fenêtre modale
import { IGymnase } from "../interface/IGymnase";
import { ICreneau } from "../interface/ICreneau";
import { IJoueur } from "../interface/IJoueur";
import {
	loadGymnases,
	loadCreneaux,
	loadJoueurs,
	loadjoueurshabituelsbycreneau,
	findSaisie,
	ExecuteValidation,
	loadJoueursBySaisie,
	ModifyValidation,
} from "../api/LocalData";
import { Saisie } from "../class/saisie";
import { saisonActuelle } from "../config";
import { set } from "date-fns";

const Container: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [selectedGymnasium, setSelectedGymnasium] = useState<number>(0);
	const [selectedDate, setSelectedDate] = useState<string>("");
	const [selectedDateWeekDayNumber, setSelectedDateWeekDayNumber] =
		useState<number>(0);
	const [selectedDateWeekDay, setSelectedDateWeekDay] = useState<string>("");
	const [selectedCreneau, setSelectedCreneau] = useState<number>(0);
	const [selectedPeriod, setSelectedPeriod] = useState<string>("");
	const [showAddPlayerPopup, setShowAddPlayerPopup] = useState(false);

	const simulatedGymnasiums: Promise<IGymnase[]> = loadGymnases(); // Données simulées
	const simulatedPlayers: Promise<IJoueur[]> = loadJoueurs(); // Données simulées

	const [gymnasiums, setGymnasiums] = useState<IGymnase[]>([]);
	const [joueurs, setJoueurs] = useState<IJoueur[]>([]);
	const [joueursPointage, setJoueursPointage] = useState<IJoueur[]>([]);
	const [creneaux, setCreneaux] = useState<ICreneau[]>([]);
	const [joueursHabituels, setJoueursHabituels] = useState<IJoueur[]>([]);
	const [isAddJoueursHabituels, setisAddJoueursHabituels] = useState(false);
	const [showNotification, setShowNotification] = useState(false); // Pour afficher la notification
	const [searchBoxValue, setSearchBoxValue] = useState(""); // Pour ajouter un joueur déja connu
	const [isParameterSectionOpen, setIsParameterSectionOpen] = useState(true); //parameter de la section
	const [isPlayerListSectionOpen, setIsPlayerListSectionOpen] = useState(true); //parameter de la section
	const [isSaisieExistante, setIsSaisieExistante] = useState(false); //parameter de la section
	const [saisieExistante, setSaisieExistante] = useState<Saisie | null>(null);
	const [joueursExistants, setJoueursExistants] = useState<IJoueur[]>([]);
	const [joueursHasChanged, setJoueursHasChanged] = useState(false);
	const [isSaisieHistorique, setIsSaisieHistorique] = useState(false); //parameter de la section

	const handleGymnasiumChange = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		const gymnasiumId = Number(event.target.value);
		console.log("numero du gymnase :", gymnasiumId);
		setSelectedGymnasium(gymnasiumId);
	};

	const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newDate = event.target.value;
		//TODO recuperer le chargement des dates et l affecter
		const weekDay = new Date(newDate).toLocaleString("fr", { weekday: "long" });
		const weekDayNumber = new Date(newDate).getDay();
		console.log(weekDay, " - numero du jour :", weekDayNumber);
		setSelectedDate(newDate);
		setSelectedDateWeekDay(weekDay);
		setSelectedDateWeekDayNumber(weekDayNumber);
	};

	const handleCourtChange = (event: any) => {
		const creneauId = Number(event.target.value);
		setSelectedCreneau(creneauId);
		console.log("creneau selectionné :", event.target.value);
		// Filtrage des joueurs en fonction de l'historique de pointage pour le créneau sélectionné
		const selectedCrenau = creneaux.find(
			(creneau) => creneau.id === event.target.value
		);
		setSelectedPeriod(
			selectedCrenau?.debut_creneau.toString().substring(0, 5) +
				" " +
				selectedCrenau?.fin_creneau.toString().substring(0, 5)
		);
	};

	const handleValidation = async () => {
		// 0. on instancie History

		// 1. Insérer les nouveaux joueurs cochés "present=true"
		const nouveauxJoueurs = joueursPointage.filter((player) => player.present);

		// 2. Créer une instance de la classe Saisie avec les données appropriées
		const nouvelleSaisie = new Saisie(
			selectedGymnasium,
			selectedCreneau,
			new Date(selectedDate),
			saisonActuelle
		);

		// 3. Appeler la fonction handleValidation de localData.ts
		ExecuteValidation(nouveauxJoueurs, nouvelleSaisie).then(
			(result: boolean) => {
				if (result === true) {
					toast.success("Le pointage a été réalisé avec succès!");
					console.log("Pointage réussi: ", nouvelleSaisie);
					handleNotificationReturnHome();
				}
			}
		);
	};

	const handleModification = async () => {
		// 0. Supprimer tous pointages
		const modificationJoueurs = joueursPointage.filter(
			(player) => player.present
		);

		console.info("saisie existante : ", saisieExistante);

		// 1. Insérer les nouveaux joueurs cochés "present=true"
		const nouveauxJoueurs = joueursPointage.filter((player) => player.present);
		// 3. Appeler la fonction handleValidation de localData.ts

		const sai: Saisie = (saisieExistante as Saisie) ?? null;
		ModifyValidation(modificationJoueurs, sai, joueursHasChanged).then(
			(result: boolean) => {
				if (result === true) {
					toast.success("La mise à jour a été réalisé avec succès!");
					console.log("La mise à jour OK de la saisie: ", saisieExistante);
					handleNotificationReturnHome();
				}
			}
		);
	};

	const handleNotification = () => {
		setShowNotification(true);
		setTimeout(() => setShowNotification(false), 1000); // Masquer la notification après 3 secondes
	};
	const handleNotificationReturnHome = () => {
		setShowNotification(true);
		setTimeout(() => {
			setShowNotification(false);
			navigate("/");
		}, 3000);
		// Masquer la notification après 3 secondes
	};

	const handleAddNewPlayer = (prenomSaisi: string, nomSaisi: String) => {
		const newPlayer = {
			id: joueurs.length + 1,
			prenom: prenomSaisi.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
				letter.toUpperCase()
			),
			nom: nomSaisi.toUpperCase(),
			licence: 0,
			nbCreneau: 0,
			present: true,
			newadded: true,
		};

		addPlayerToPlayersList(newPlayer);
		setShowAddPlayerPopup(false);
	};

	const addPlayerToPlayersList = (player: any) => {
		const updatedPlayers = [...joueursPointage, player];
		setJoueursPointage(updatedPlayers);
	};

	const handleAddNewPlayerFromDropdown = (selectedOption: any) => {
		if (selectedOption) {
			const playerId = selectedOption.value;
			const selectedPlayer = joueurs.find((player) => player.id === playerId);
			console.trace("joueur sélectionné ", selectedPlayer);
			if (selectedPlayer) {
				let nbCreneauJoueurHabituel: number | any = undefined;
				if (joueursHabituels) {
					nbCreneauJoueurHabituel = joueursHabituels.find(
						(jouhab) => jouhab.id === playerId
					)?.nb_creneau;
				}

				const newPlayer = {
					...selectedPlayer,
					newadded: false,
					present: true,
					nb_creneau: Number(nbCreneauJoueurHabituel),
				};

				if (
					!joueursPointage.some((player) => player.id === selectedPlayer.id)
				) {
					setJoueursPointage([...joueursPointage, newPlayer]);
					toast.success("Nouveau joueur ajouté avec succès !");
					setSearchBoxValue(""); // Réinitialiser la recherche
					handleNotification();
				} else {
					toast.error("Ce joueur existe déjà dans la liste.");
				}
			}
		}
	};

	const handleTogglePlayerPresent = (index: number, newValue: boolean) => {
		const updatedPlayers = [...joueursPointage];
		updatedPlayers[index].present = newValue;
		setJoueursPointage(updatedPlayers);
	};

	async function loadSaisieExistante(SaisieData: Saisie) {
		console.log("valeur etat Saisie existante ou pas : ", SaisieData);
		if (SaisieData !== undefined && SaisieData !== null) {
			setIsSaisieExistante(true);
			setSaisieExistante(SaisieData);
			const joueursBySaisie: IJoueur[] = await loadJoueursBySaisie(
				SaisieData.id
			);

			console.log("liste des joueurs saisies : ", joueursBySaisie);
			if (joueursBySaisie !== undefined) {
				setJoueursPointage(joueursBySaisie);
				setJoueursExistants(joueursBySaisie);
			}
		} else {
			setIsSaisieExistante(false);
		}
	}

	useEffect(() => {
		//Verification si une existe est existante
		if (location.state?.saisieAModifier) {
			const saisieHistorique = location.state.saisieAModifier as Saisie;
			console.log(
				"APPEL chargement de la saisie, les listes et les joueurs de la saisie=",
				saisieExistante
			);

			loadSaisieExistante(saisieHistorique);
			setIsSaisieHistorique(true);
			console.log("gymnase set", saisieHistorique.gymnase_id);
			setSelectedGymnasium(saisieHistorique.gymnase_id);
			const creneaux: Promise<ICreneau[]> = loadCreneaux(); // Données simulées
			Promise.all([creneaux]).then(([creneauxData]) => {
				const selectedCrenau = creneauxData.find(
					(creneau) => creneau.id === saisieHistorique.creneau_id
				);
				setSelectedPeriod(
					selectedCrenau?.debut_creneau.toString().substring(0, 5) +
						" " +
						selectedCrenau?.fin_creneau.toString().substring(0, 5)
				);
			});
			//TODO recuperer le chargement des dates et l affecter en doublon
			if (saisieHistorique.date_saisie instanceof Date) {
				console.log(
					"date saisie : ",
					saisieHistorique.date_saisie.toLocaleDateString("fr-FR")
				);
				const weekDay = saisieHistorique.date_saisie.toLocaleDateString(
					"fr-FR",
					{
						weekday: "long",
					}
				);
				const weekDayNumber = saisieHistorique.date_saisie.getDay();
				console.log(weekDay, " - numero du jour :", weekDayNumber);

				setSelectedDate(
					saisieHistorique.date_saisie.toLocaleDateString("sv-SE")
				);
				setSelectedDateWeekDay(weekDay);
				setSelectedDateWeekDayNumber(weekDayNumber);
			}
			//const dateSaisie = new Date(saisieHistorique.date_saisie.toDateString());

			setSelectedCreneau(saisieHistorique.creneau_id);
			location.state.saisieAModifier = undefined;
		}
	}, [location.state]);

	useEffect(() => {
		// Chargement initial des données
		Promise.all([simulatedGymnasiums, simulatedPlayers])
			.then(([gymnasiumData, joueurData]) => {
				setGymnasiums(gymnasiumData);
				setJoueurs(joueurData);

				// ... Autres logiques d'initialisation ...
			})
			.catch((error) => {
				// Gérer les erreurs de chargement des données
				console.error("Error loading data:", error);
			});
	}, []);

	useEffect(() => {
		if (
			(selectedGymnasium &&
				selectedDate &&
				selectedDateWeekDayNumber !== null) ||
			(selectedCreneau > 0 && isSaisieHistorique)
		) {
			console.log(
				"APPEL SilumatedPlayersHabitudeCreneau, valeur selectedCreneau =",
				selectedCreneau
			);

			const simulatedCreneaux: Promise<ICreneau[]> = loadCreneaux(); // Données simulées

			// Filtrage des créneaux en fonction de selectedGymnasium et selectedDate
			Promise.all([simulatedCreneaux])
				.then(([creneauxData]) => {
					console.trace("before set creneau:", creneauxData);
					let filteredCreneaux: ICreneau[] = [];
					if (!isSaisieHistorique) {
						filteredCreneaux = creneauxData.filter(
							(creneau) =>
								Number(creneau.gymnase_id) === selectedGymnasium &&
								Number(creneau.jour_semaine) === selectedDateWeekDayNumber
						);
						console.trace(
							"gymnasium:",
							selectedGymnasium,
							"daynumber:",
							selectedDateWeekDayNumber,
							"after set creneau:",
							filteredCreneaux
						);
					} else {
						const asetcre = selectedCreneau;
						filteredCreneaux = creneauxData.filter(
							(creneau) => String(creneau.id) === asetcre.toString()
						);
						console.trace(
							"creneau id:",
							asetcre,
							"after set creneau:",
							filteredCreneaux
						);
						setSelectedCreneau(asetcre);
					}

					setCreneaux(filteredCreneaux);

					// Créez la promesse loadJoueursHabitueByCreneau ici
					const simulatedPlayersHabitueByCreneau: Promise<IJoueur[]> =
						loadjoueurshabituelsbycreneau(selectedCreneau);

					Promise.all([simulatedPlayersHabitueByCreneau])
						.then(([joueurIdHabitue]) => {
							const joueurHabitue = joueurIdHabitue;
							setJoueursHabituels(joueurHabitue);
						})
						.catch((error) => {
							// Gérer les erreurs de chargement des données
							console.error("Error loading data:", error);
						});
				})
				.catch((error) => {
					// Gérer les erreurs de chargement des données
					console.error("Error loading data:", error);
				});
		}
	}, [
		selectedGymnasium,
		selectedDate,
		selectedDateWeekDayNumber,
		selectedCreneau,
		isSaisieHistorique,
	]);

	useEffect(() => {
		if (selectedCreneau > 0 && !isSaisieHistorique) {
			console.log(
				"APPEL SilumatedPlayersHabitudeCreneau, valeur selectedCreneau =",
				selectedCreneau,
				" selectecDate=",
				selectedDate
			);
			setJoueursPointage([]);
			setJoueursHabituels([]);
			findSaisie(selectedCreneau, selectedDate)
				.then(async (SaisieData) => {
					if (SaisieData) {
						loadSaisieExistante(SaisieData);
					} else {
						loadJoueursBySaisie;
					}
				})
				.catch((error) => {
					console.error("Error loading data:", error);
				});

			// Créez la promesse loadJoueursHabitueByCreneau ici
			const simulatedPlayersHabitueByCreneau: Promise<IJoueur[]> =
				loadjoueurshabituelsbycreneau(selectedCreneau);

			Promise.all([simulatedPlayersHabitueByCreneau])
				.then(([joueurIdHabitue]) => {
					const joueurHabitue = joueurIdHabitue;
					setJoueursHabituels(joueurHabitue);
				})
				.catch((error) => {
					// Gérer les erreurs de chargement des données
					console.error("Error loading data:", error);
				});
		}
	}, [selectedCreneau]);

	useEffect(() => {
		if (joueursPointage === joueursExistants) {
			setJoueursHasChanged(false);
			console.log(
				"APPEL SilumatedPlayersHabitudeCreneau, valeur selectedCreneau =",
				selectedCreneau
			);
		} else {
			setJoueursHasChanged(true);
			console.log(
				"APPEL SilumatedPlayersHabitudeCreneau, valeur selectedCreneau =",
				selectedCreneau
			);
		}
	}, [joueursPointage]);
	return (
		<Layout isConnected={false}>
			<div className='content'>
				<h2 className='title divider-line'>
					<button
						className='secondary-button accordion-toggle-button'
						onClick={() => setIsParameterSectionOpen(!isParameterSectionOpen)}
					>
						<FontAwesomeIcon
							icon={isParameterSectionOpen ? faMinusSquare : faPlusSquare}
							className='accordion-toggle-icon information'
						/>
					</button>
					Choisir un créneau
				</h2>
				<div
					className={`creneau-accordion ${
						isParameterSectionOpen ? "open" : "closed"
					}`}
				>
					<div className='accordion-header'>
						<FontAwesomeIcon
							icon={selectedGymnasium ? faCheckCircle : faTimesCircle}
							className={
								selectedGymnasium
									? "accordion-icon-field green"
									: "accordion-icon-field red"
							}
						/>
						<span
							className={
								selectedGymnasium
									? "accordion-label-field information green"
									: "accordion-label-field information red"
							}
						>
							gymnase
						</span>
						<select
							className='accordion-text-field'
							value={selectedGymnasium}
							onChange={handleGymnasiumChange}
							disabled={isSaisieHistorique}
						>
							<option value=''>Sélectionner un gymnase</option>
							{gymnasiums.map((gymnasium) => (
								<option key={gymnasium.id} value={gymnasium.id}>
									{gymnasium.nom_court}
								</option>
							))}
						</select>
					</div>
					<div className='accordion-header'>
						<FontAwesomeIcon
							icon={selectedDate ? faCheckCircle : faTimesCircle}
							className={
								selectedDate
									? "accordion-icon-field green"
									: "accordion-icon-field red"
							}
						/>
						<span className='accordion-label-field information green '>
							{selectedDateWeekDay}
						</span>
						<input
							className='accordion-text-field'
							type='date'
							value={selectedDate}
							onChange={handleDateChange}
							disabled={isSaisieHistorique}
						/>
					</div>

					<div className='accordion-header'>
						<FontAwesomeIcon
							icon={selectedCreneau ? faCheckCircle : faTimesCircle}
							className={
								selectedCreneau
									? "accordion-icon-field green"
									: "accordion-icon-field red"
							}
						/>
						<span className='accordion-label-field information green '>
							{selectedPeriod}
						</span>
						<select
							className='accordion-text-field'
							value={selectedCreneau}
							onChange={handleCourtChange}
							disabled={isSaisieHistorique}
						>
							<option value=''>Sélectionnez un créneau</option>
							{creneaux.map((creneau) => (
								<option key={creneau.id} value={creneau.id}>
									{creneau.nom}
								</option>
							))}
						</select>
						{selectedCreneau > 0 &&
							joueursHabituels.length > 0 &&
							isAddJoueursHabituels === false &&
							isSaisieExistante === false && (
								<button
									className='secondary-button information green check'
									onClick={() => [
										setJoueursPointage(joueursHabituels),
										setisAddJoueursHabituels(true),
									]}
								>
									<FontAwesomeIcon icon={faUserCheck} />
								</button>
							)}
					</div>

					{selectedCreneau > 0 && (
						<div className='accordion-header-search'>
							<div className='searchbox-item'>
								<span className='text-field searchbox'>
									<Select
										options={joueurs.map((player) => ({
											value: player.id,
											label: `${player.nom} ${player.prenom}`,
										}))}
										value={null}
										onChange={(selectedOption) => {
											handleAddNewPlayerFromDropdown(selectedOption);
										}}
										isClearable
										placeholder='Recherchez un adhérent'
									/>
								</span>

								<button
									className='secondary-button add searchbox-button'
									onClick={() => setShowAddPlayerPopup(true)}
								>
									<FontAwesomeIcon icon={faPlusCircle} /> nouveau
								</button>
							</div>
						</div>
					)}
				</div>
				<h2 className='title divider-line'>
					<button
						className='secondary-button accordion-toggle-button'
						onClick={() => setIsPlayerListSectionOpen(!isPlayerListSectionOpen)}
					>
						<FontAwesomeIcon
							icon={isPlayerListSectionOpen ? faMinusSquare : faPlusSquare}
							className='accordion-toggle-icon information'
						/>
					</button>
					pointer les joueurs :{" "}
					<span className={selectedCreneau ? "green" : "red"}>
						{" "}
						{joueursPointage.filter((poi) => poi.present == true).length} joueur
						{joueursPointage.filter((poi) => poi.present == true).length > 1
							? "s"
							: ""}
					</span>{" "}
					{joueursPointage.length > 0 && isSaisieExistante === false && (
						<span>
							<button
								className='secondary-button delete searchbox-button '
								onClick={() => [
									setJoueursPointage([]),
									setisAddJoueursHabituels(false),
								]}
							>
								<FontAwesomeIcon icon={faUserAltSlash} />
							</button>
						</span>
					)}
				</h2>
				<div
					className={`pointage-accordion ${
						isPlayerListSectionOpen ? "open" : "closed"
					}`}
				>
					<div className='  scrollbar'>
						{joueursPointage.map((player, index) => (
							<div className='person-item' key={index}>
								<span className='person-name'>
									<span className='person'>{player.nom}</span>
									<span className='person'>{player.prenom}</span>
								</span>

								<div className='person-icon'>
									<div
										className={`${
											player.licence == 1 ? "border-green" : "border-red"
										}`}
									>
										<span className='person-licence'>
											<FontAwesomeIcon
												icon={
													player.id in joueursHabituels
														? faUserCheck
														: faUserNinja
												}
												className={`pointage-icon ${
													player.nb_creneau >= 10
														? "green"
														: player.nb_creneau >= 5
														? "orange"
														: "black"
												}`}
											/>
										</span>{" "}
										<span className='person-count'>
											{
												joueursHabituels.find((jou) => jou.id == player.id)
													?.nb_creneau
											}
										</span>
									</div>
								</div>

								<span className='person'>
									<ToggleElement
										isChecked={player.present}
										onChange={(newValue) =>
											handleTogglePlayerPresent(index, newValue)
										}
									/>
								</span>
							</div>
						))}
					</div>
				</div>
			</div>
			{joueursPointage.filter((poi) => poi.present == true).length > 0 && (
				<div className='floating-principal-button'>
					{!isSaisieExistante && (
						<button
							className='primary-button check'
							onClick={() => handleValidation()}
						>
							<FontAwesomeIcon icon={faCheckCircle} /> Valider
						</button>
					)}
					{isSaisieExistante && (
						<button
							className='primary-button check'
							onClick={() => handleModification()}
						>
							<FontAwesomeIcon icon={faUserEdit} /> Modifier
						</button>
					)}
				</div>
			)}
			<ToastContainer />
			<AddPlayerPopup
				showModal={showAddPlayerPopup} // Utilisez showAddPlayerModal au lieu de showAddPlayerPopup
				onClose={() => setShowAddPlayerPopup(false)} // Utilisez setShowAddPlayerPopup au lieu de setShowAddPlayerPopup
				onAddPlayer={(firstName, lastName) => {
					handleAddNewPlayer(firstName, lastName); // Utilisez handleAddNewPlayer au lieu de handleAddNewPlayerPopup
					setShowAddPlayerPopup(false); // Utilisez setShowAddPlayerPopup au lieu de setShowAddPlayerPopup
				}}
			/>
		</Layout>
	);
};

const NewSaisie = () => {
	return (
		<div className='rootpage container'>
			<Header />
			<Container />
			<Footer />
		</div>
	);
};

export default NewSaisie;
