import React, { useState,useEffect } from 'react';
import "../styles.css";
import "./container.css";
import Header from '../header/header';
import Footer from '../footer/footer';
import Layout from "../layout/layout";

const Container: React.FC = () => {
  const [selectedGymnase, setSelectedGymnase] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isConnected = false;


  const handleGymnaseChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedGymnase(event.target.value);
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedType(event.target.value);
  };
  // Simuler une requête asynchrone pour obtenir les créneaux
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Temps de simulation de la requête en millisecondes
  }, [selectedGymnase, selectedType]);

  return (
    <Layout isConnected={isConnected}>
      <div className="content">
          <h1 className="title">Créneau</h1>
      <div className="form-group">
        <label htmlFor="gymnase">Gymnase : </label>
        <select id="gymnase" value={selectedGymnase} onChange={handleGymnaseChange}>
          <option value="">Sélectionnez un gymnase</option>
          <option value="Chardon">Chardon</option>
          <option value="Barthélémy">Barthémély</option>
          <option value="Dessaux">Dessaux</option>
          <option value="Céline-Lebrun">Céline-Lebrun</option>
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="type">Type de créneau : </label>
        <select id="type" value={selectedType} onChange={handleTypeChange}>
          <option value="">Sélectionnez un type</option>
          <option value="jeu-libre">Jeu libre</option>
          <option value="jeune">Jeune</option>
          <option value="encadrement">Encadrement</option>
        </select>
      </div>
      {isLoading ? (
        <div className="loading">Chargement en cours...</div>
      ) : (
        <div className="creneaux-list">
              
          {/* Remplacez les créneaux fictifs par vos données réelles */}
          {[1, 2, 3, 4, 5].map((creneau) => (
            <div key={creneau} className="creneau-item">
              Créneau {creneau}
            </div>
          ))}
        </div>
      )}
      </div>
    </Layout>
  );
};

const Creneau = () => {
    return (
      <div className="rootpage">
       <Header />
       <Container />
       <Footer />
      </div>
    
    );
  };

export default Creneau;
