import "reflect-metadata";
import React from "react";
import { Route, Routes } from "react-router-dom";
import "./styles.css";
import Home from "./container/home";
import Saisie from "./container/saisie";
import Connexion from "./container/connexion";
import Creneau from "./container/creneau";
import Contact from "./container/contact";
import HistoriqueSaisie from "./container/historiquesaisie";
//import Login from "./Login";

const isFinish = true; // Condition pour désactiver la route '/finish'
/*{isFinish && <Route path="/saisie" Component={Saisie} />}
{isFinish && <Route path="/creneaux" component={Creneaux} />}
{isFinish && <Route path="/contact" component={Contact} />}
{isFinish && <Route path="/support" component={Support} />}
{isFinish && <Route path="/login" component={Login} />}*/

const App: React.FC = () => {
	return (
		<Routes>
			<Route path='/' element={<Home />} />
			<Route path='/saisie' element={<Saisie />} />
			<Route path='/connexion' element={<Connexion />} />
			<Route path='/creneau' element={<Creneau />} />
			<Route path='/contact' element={<Contact />} />
			<Route path='/historique' element={<HistoriqueSaisie />} />
		</Routes>
	);
};

export default App;
