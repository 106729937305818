// Gymnase.ts
export class Gymnase {
	constructor(
		public id: number,
		public nom: string,
		public nom_court: string,
		public capacite_terrain: number,
		public capacite_loisir: number,
		public capacite_entrainement: number
	) {}
}
