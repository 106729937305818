import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import "./addPlayerModal.css"; // Assurez-vous que le chemin est correct

interface AddPlayerModalProps {
  showModal: boolean;
  onClose: () => void;
  onAddPlayer: (firstName: string, lastName: string) => void;
}

const AddPlayerPopup: React.FC<AddPlayerModalProps> = ({ showModal, onClose, onAddPlayer }) => {
  const [newPlayerFirstName, setNewPlayerFirstName] = useState("");
  const [newPlayerLastName, setNewPlayerLastName] = useState("");

  if (!showModal) {
    return null; // Ne rend rien si showModal est false
  }

  return (
    <div className="modal-overlay">
      <div className="add-player-modal">
        <span className="close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </span>
        <h3>Ajouter un nouveau joueur</h3>
        <input
          type="text"
          value={newPlayerFirstName}
          onChange={(e) => setNewPlayerFirstName(e.target.value)}
          placeholder="Prénom"
        />
        <input
          type="text"
          value={newPlayerLastName}
          onChange={(e) => setNewPlayerLastName(e.target.value)}
          placeholder="NOM"
        />
        <button
          className="primary-button add"
          onClick={() => {
            onAddPlayer(newPlayerFirstName, newPlayerLastName);
            setNewPlayerFirstName("");
            setNewPlayerLastName("");
          }}
        >
          <FontAwesomeIcon icon={faCheckCircle} /> Ajouter
        </button>
      </div>
    </div>
  );
};

export default AddPlayerPopup;
